import React from 'react'
import logo from '../../img/new/Jarsis.ai.png'
import homeActive from '../../img/new/Home-active.svg'
import home from '../../img/new/Home.svg'
import dashboard from '../../img/new/dashboard.svg'
import dashboardActive from '../../img/new/dashboard-active.svg'
import logout from '../../img/new/logout.svg'
import { Link, useLocation } from 'react-router-dom'


const SideNavBar = () => {

    const location = useLocation();

    return (
        <main className='tw-border tw-shadow-sm tw-rounded-3xl tw-px-5 tw-pt-5 tw-w-[16%] '>

            {/* title and logo */}
            <section className='tw-flex tw-gap-x-2 tw-items-center'>
                <img src={logo} alt="" className='tw-w-8' />
                <p className='tw-font-[600] tw-text-sm'>jarsis.ai</p>
            </section>

            {/* interview ai */}
            <section>
                <p className='tw-text-[#5D5D5D] tw-text-sm tw-pt-10 tw-border-b tw-pb-2 tw-mb-5'>Interview AI</p>


                <Link to='/super_admin' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit '>
                    {location.pathname === '/super_admin' ?
                        <>
                            <img src={homeActive} alt="" />
                            <p className='tw-text-[#3737FF]' >Home</p>
                        </>
                        :

                        <>
                            <img src={home} alt="" />
                            <p>Home</p>
                        </>}
                </Link>


                <Link to='/aiList' className='tw-flex tw-gap-x-2 tw-items-center tw-my-3 tw-cursor-pointer tw-w-fit '>
                    {location.pathname === '/aiList' ?
                        <>
                            <img src={dashboardActive} alt="" />
                            <p className='tw-text-[#3737FF]' >AI List</p>
                        </>
                        :

                        <>
                            <img src={dashboard} alt="" />
                            <p>AI List</p>
                        </>}
                </Link>





            </section>

            <div onClick={() => { window.location.href = '/landing' }} className='tw-flex tw-gap-x-2 tw-items-center tw-mt-14 tw-mb-5 tw-cursor-pointer tw-w-fit '>
                <img src={logout} alt="" />
                <p>Dashboard</p>
            </div>



        </main>
    )
}

export default SideNavBar