import React, { useState } from 'react';
import ReactFlow, { Background, Controls } from 'reactflow';
import 'reactflow/dist/style.css';

const FlowDiagram = () => {
    // const [nodes, setNodes] = useState([
    //     { id: '1', position: { x: 100, y: 0 }, data: { label: 'Start the Interview' }, style: { background: '#ffcccc', padding: 10 } },
    //     { id: '2', position: { x: 100, y: 100 }, data: { label: 'AI Prompt' }, style: { background: '#cce7ff', padding: 10 } },
    //     { id: '3', position: { x: 100, y: 200 }, data: { label: 'Emotional Prompt' }, style: { background: '#ffffcc', padding: 10 } },
    //     { id: '4', position: { x: 100, y: 300 }, data: { label: 'Logical Prompt' }, style: { background: '#cce7ff', padding: 10 } },
    //     { id: '5', position: { x: 100, y: 400 }, data: { label: 'End the Interview' }, style: { background: '#ffcccc', padding: 10 } },
    // ]);

    // const [edges, setEdges] = useState([
    //     { id: 'e1-2', source: '1', target: '2', animated: true },
    //     { id: 'e2-3', source: '2', target: '3', animated: true },
    //     { id: 'e3-4', source: '3', target: '4', animated: true },
    //     { id: 'e4-5', source: '4', target: '5', animated: true },
    // ]);

    const [nodes, setNodes] = useState([]);

    const [edges, setEdges] = useState([]);

    const [aiPromptText, setAiPromptText] = useState('AI Prompt'); // State for AI Prompt text

    // Update the "AI Prompt" node text
    const updateAiPrompt = () => {
        setNodes((prevNodes) =>
            prevNodes.map((node) =>
                node.id === '2' // Check if it's the "AI Prompt" node
                    ? { ...node, data: { ...node.data, label: aiPromptText } }
                    : node
            )
        );
    };

    const addNode = () => {
        const newNode = {
            id: (nodes.length + 1).toString(),
            position: { x: 100, y: nodes.length * 100 },
            data: { label: `New Node ${nodes.length + 1}` },
            style: { background: '#e0f7fa', padding: 10 },
        };

        const newEdge = {
            id: `e${nodes.length}-${nodes.length + 1}`,
            source: (nodes.length).toString(),
            target: (nodes.length + 1).toString(),
            animated: true,
        };

        setNodes((nds) => [...nds, newNode]);
        setEdges((eds) => [...eds, newEdge]);
    };


    const elementClickHandler = (e, node) => {
        console.log("EEE-------->", node)
    }

    return (
        <div style={{ height: '600px', width: '100%' }}>
            <ReactFlow nodes={nodes} edges={edges} fitView onNodeClick={(e, node) => elementClickHandler(e, node)}>
                <Background className='tw-bg-white' />
                <Controls />
            </ReactFlow>
            <div style={{ marginTop: '20px' }}>
                <input
                    type="text"
                    value={aiPromptText}
                    onChange={(e) => setAiPromptText(e.target.value)}
                    placeholder="Enter AI Prompt Text"
                    style={{
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        padding: '5px',
                        marginRight: '10px',
                    }}
                />
                <button
                    onClick={updateAiPrompt}
                    style={{
                        padding: '5px 10px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Update AI Prompt
                </button>
                <button onClick={addNode} style={{ marginTop: 20 }}>
                    Add Node
                </button>
            </div>
        </div>
    );
};

export default FlowDiagram;
