import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";


const AIPopup = (props) => {
    const { showForm, handleCloseForm, handlingAddInterview, formData } = props;

    const { register, handleSubmit, setValue, reset, clearErrors, watch, formState: { errors } } = useForm();

    const onSubmit = data => {
        console.log(data)
        handlingAddInterview(data);
        setValue("name", "");
        clearErrors("name");
        reset();
    }


    return (
        <Modal show={showForm} onHide={() => handleCloseForm()}>
            <Modal.Header closeButton>
                <Modal.Title>Create AI</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-14 tw-mx-auto p-20">
                        <label htmlFor="" className='tw-mb-2'>Title</label>
                        <input id="message" autoComplete='off' {...register("name", { required: true })} rows="4" className="tw-block tw-p-2.5 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-rounded-lg border tw-border-gray-300 focus:tw-ring-blue-500 focus:tw-border-blue-500" placeholder="Title here..."></input>
                    </div>
                    {errors.name && <p className='tw-text-red-500'>Please enter AI Name</p>}

                    <div className="mt-14 tw-mx-auto p-20">
                        <label htmlFor="" className='tw-mb-2'>Language</label>
                        <select name="" id="" {...register("language", { required: true })} className="tw-block  tw-p-2.5  tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-rounded-lg border tw-border-gray-300 focus:tw-ring-blue-500 focus:tw-border-blue-500" >
                            <option hidden value=''  >Choose an Language</option>
                            <option value="English">English</option>
                            <option value="Hindi">Hindi</option>
                        </select>
                    </div>
                    {errors.language && <p className='tw-text-red-500'>Please Select an Language</p>}

                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleCloseForm()}>
                    Close
                </Button>
                <Button variant="primary" type='submit' form="hook-form">
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AIPopup