import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const AudioConversation = (props) => {
    const { showAudioChats, setShowAudioChats, newaudioData, candidateName } = props;
    const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
    const [isPlaying, setIsplaying] = useState(false);

    const audioRef = useRef(null);
    const saudioRef = useRef([]);
    const audioData = newaudioData.sort(({ sequenceNumber: a }, { sequenceNumber: b }) => a - b);
    //console.log("audioData------>", audioData)
    //  const sortAudio = (data) => {
    //     const res = data.sort(({ sequenceNumber: a }, { sequenceNumber: b }) => b - a);
    //     console.log(res);
    // }

    useEffect(() => {
        // Play the current audio file when the component renders or the index changes
        //console.log("audioData------->", saudioRef)
        try {
            if (isPlaying) {
                if (saudioRef.current) {
                    //audioRef.current.mute = true;
                    // audioRef.current.play();
                    console.log("audioData11111------->", saudioRef.current[currentAudioIndex])
                    if (saudioRef.current[currentAudioIndex]) {
                        saudioRef.current[currentAudioIndex].play();
                        //saudioRef.current[currentAudioIndex].scrollIntoView()
                    }
                    //document.querySelectorAll('#check' + currentAudioIndex)[0].play();
                    //document.querySelectorAll('#check' + currentAudioIndex)[0].focus();
                }
            } else {
                if (saudioRef.current) {
                    // audioRef.current.pause();
                    if (saudioRef.current[currentAudioIndex]) {
                        saudioRef.current[currentAudioIndex].pause();
                        //saudioRef.current[currentAudioIndex].scrollIntoView()
                    }
                    // document.querySelectorAll('#check' + currentAudioIndex)[0].pause();
                    //  document.querySelectorAll('#check' + currentAudioIndex)[0].focus();
                }
            }
        } catch (e) {
            handleAudioEnd();
        }

    }, [currentAudioIndex, isPlaying]);

    const handleAudioEnd = () => {
        // Move to the next audio file when the current one ends
        try {
            if (currentAudioIndex < audioData.length - 1) {
                setCurrentAudioIndex((prev) => prev + 1);
            }
        } catch (e) {
            setCurrentAudioIndex((prev) => prev + 1);
        }

    };

    const handleplay = () => {
        setIsplaying(!isPlaying);
    }

    return (
        <Modal show={showAudioChats} onHide={() => setShowAudioChats(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Chat Conversation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='tw-flex tw-justify-between'>
                    <Button onClick={() => handleplay()}>Play</Button>
                    <Button onClick={() => handleplay()}>Pause</Button>
                    {audioData[currentAudioIndex] && (
                        <audio
                            ref={audioRef}
                            src={audioData[currentAudioIndex].url}
                            onEnded={handleAudioEnd}
                        />
                    )}
                </div>
                <div class="tw-h-screen tw-flex tw-flex-col tw-bg-gray-100 tw-p-4 tw-mt-5">
                    <div class="tw-flex-1 tw-overflow-y-auto tw-space-y-4">

                        {audioData.map((val, index) => {
                            return !val?.isHumanAudio ?
                                <div key={"ai" + index} class="tw-flex tw-items-start">

                                    <div class="tw-bg-blue-500 tw-text-white tw-p-3 tw-rounded-lg tw-rounded-bl-none tw-shadow">
                                        <strong><p>Ai</p></strong>
                                        <audio onEnded={handleAudioEnd} ref={el => (saudioRef.current[index] = el)} id={"check" + index} src={val?.url} controls />
                                    </div>
                                </div> :
                                <div key={"user" + index} class="tw-flex tw-items-start tw-justify-end">
                                    <div class="tw-bg-gray-200 tw-text-gray-800 tw-p-3 tw-rounded-lg tw-rounded-br-none tw-shadow">
                                        <div className='tw-flex tw-flex-col tw-items-end'><strong><p>{candidateName}</p></strong></div>
                                        <audio onEnded={handleAudioEnd} ref={el => (saudioRef.current[index] = el)} id={"check" + index} src={val?.url} controls />
                                    </div>
                                </div>
                        })}
                    </div>
                </div>


            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowAudioChats(false)}>
                    Close
                </Button>
                {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
            </Modal.Footer>
        </Modal>
    )
}

export default AudioConversation