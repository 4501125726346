import React, { useEffect, useState } from 'react'
import SideNavBar from './SideNavBar'
import Loader from '../../layout/Loader'
import filter from '../../img/new/filter.svg'
import search from '../../img/new/search.svg'
import dr1 from '../../img/new/doctor/dr-1.png'
import dr2 from '../../img/new/doctor/dr-2.png'
import history from '../../img/new/history.svg'
import video from '../../img/new/video.svg'
import call from '../../img/new/call.svg'
import AfterCallContent from '../TrainingAI/AfterCallContent'
import BeforeCallContent from '../TrainingAI/BeforeCallContent'
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify'

const AIList = () => {

    const [allTrainings, setAllTrainings] = useState([]);
    const [allOrgTrainings, setAllOrgTrainings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedTraining, setSelectedTraining] = useState({});
    const [allUsers, setAllUsers] = useState([]);
    const [selectedusers, setSelectedusers] = useState([]);
    const [searchWord, setSearchWord] = useState("");
    useEffect(() => {
    }, [allTrainings, selectedTraining])
    useEffect(() => {
        const getAllTrainings = async () => {
            try {
                setLoading(true);
                const res = await axios.post('https://api.jarsis.ai/training/getAllTrainings', {}, {
                    headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
                })
                setLoading(false);
                if (res?.data?.success) {
                    const { payload } = res?.data;
                    const { trainingsList } = payload;
                    const trainings = trainingsList.map(val => {
                        return {
                            id: val?._id, aiImage: val?.aiImage, name: val?.name, role: val?.role,
                            division: val?.division.map(divi => divi?.title),
                            character: val?.character.map(char => char?.title),
                            language: val?.language?.title,
                            levels: val?.levels
                        }
                    })
                    setSelectedTraining(trainings[0])
                    setAllTrainings(prev => [...prev, ...trainings]);
                    setAllOrgTrainings(prev => [...prev, ...trainings])
                }
            } catch (error) {

            }
        }

        const getAllUsers = async () => {
            try {
                setLoading(true);
                const res = await axios.post('https://api.jarsis.ai/user/getAllUsers', {}, {
                    headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
                })
                setLoading(false);
                if (res?.data?.success) {
                    const { payload } = res?.data;
                    const { usersList } = payload;
                    const trainings = usersList.map(val => {
                        return {
                            label: val?.emailId,
                            value: val?._id,
                            ...val
                        }

                    })
                    //   setSelectedTraining(trainings[0])
                    setAllUsers(prev => [...prev, ...trainings]);
                }
            } catch (error) {

            }
        }

        getAllTrainings();
        getAllUsers();
    }, [])


    const handleSelectTrain = (data) => {
        setSelectedTraining(data)
    }


    const onChangeHandler = (name, e) => {
        setSelectedusers(e);

    }

    const getCharColor = () => {
        const items = [
            "tw-bg-red-600",
            "tw-bg-green-600",
            "tw-bg-yellow-500"
        ]
        return items[Math.floor(Math.random() * items.length)];
    }

    const onSubmitHandler = () => {
        if (selectedusers.length !== 0) {
            selectedusers.map(async (val, index) => {
                try {
                    const reqObj = {
                        candidateEmail: val?.emailId,
                        candidateName: val?.name,
                        trainingDetails: selectedTraining?.id,
                        isTraining: true
                    }
                    setLoading(true);
                    const res = await axios.post('https://api.jarsis.ai/interview/createInterview', reqObj, {
                        headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
                    })
                    if (res?.data?.success) {
                        if (selectedusers.length - 1 === index) {
                            toast.success("Training assigned to users");
                            setSelectedusers([]);
                        }
                    } else {
                        console.log("ERR=========>" + index)
                    }
                    setLoading(false);
                } catch (e) {
                    setLoading(false);
                }
            })
        }

    }

    const searchWOrdHandler = (e) => {
        const value = e.target.value;
        const existArray = JSON.parse(JSON.stringify(allOrgTrainings));
        try {
            if (value) {
                const filterArry = existArray.filter(val => {
                    return val?.name.toLowerCase().includes(value.toLowerCase()) ||
                        val?.division.toString().includes(value.toLowerCase()) ||
                        val?.character.toString().includes(value.toLowerCase());
                });
                setSelectedTraining(filterArry[0]);
                setAllTrainings(filterArry);
            } else {
                setSelectedTraining(existArray[0]);
                setAllTrainings(existArray);
            }
        } catch (e) {
            console.log("EEE---->", e)
            setSelectedTraining(existArray[0]);
            setAllTrainings(existArray);
        }

    }

    const searchHandler = e => {
        const value = e.target.value;
        if (value) {
            setSearchWord(value)
        } else {
            const existArray = JSON.parse(JSON.stringify(allOrgTrainings));
            setSelectedTraining(existArray[0]);
            setAllTrainings(existArray);
        }
    }

    const deleteTraining = async () => {
        try {



            const reqObj = {
                "trainingId": selectedTraining?.id,
                "dataToUpdate": {
                    "active": false
                }
            }
            setLoading(true)
            const res = await axios.post('https://api.jarsis.ai/training/updateTraining', reqObj, {
                headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
            })

            if (res?.data?.success) {
                const existArray = JSON.parse(JSON.stringify(allOrgTrainings));
                if (existArray.length !== 0) {
                    const selectedINdex = existArray.findIndex(x => {
                        return x.id === selectedTraining?.id
                    });
                    existArray.splice(selectedINdex, 1);
                    setAllOrgTrainings(existArray);
                    setSelectedTraining(existArray[0])
                    setAllTrainings(existArray);
                    if (existArray.length !== allTrainings.length) {
                        const dubAllTrainings = allTrainings;
                        const searchSelectedINdex = dubAllTrainings.findIndex(x => {
                            return x.id === selectedTraining?.id
                        });
                        dubAllTrainings.splice(searchSelectedINdex, 1);
                        if (allTrainings.length === 0) {
                            window.location.reload();
                        } else {
                            setAllTrainings(dubAllTrainings);
                        }

                    }
                }

                toast.success("Training deleted successfully")
            } else {
                toast.error("Sorry,Training not deleted")
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <main className='tw-bg-[#fdfdff] tw-flex tw-gap-x-10 poppins tw-mt-6 tw-mx-6 tw-items-start tw-mb-20'>


            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
                <Loader />
            </div>


            {/* left side menu bar */}

            <SideNavBar />

            {/* right side content - 1 */}

            <section className='tw-w-[80%] tw-flex tw-gap-x-5'>

                <main className="tw-w-[25%] tw-border tw-rounded-3xl ">
                    {/* 1st section */}
                    <section className='tw-flex tw-justify-between tw-items-center tw-border-b tw-p-5'>
                        <p className="tw-font-medium tw-text- tw-text-gray-700">Al Trainers</p>
                    </section>

                    {/* 2nd section */}
                    <section className='tw-px-4 tw-py-5 tw-flex tw-gap-x-3 tw-border-b'>
                        {/* <div className='tw-flex tw-items-center tw-p-2 tw-cursor-pointer tw-border tw-rounded-lg tw-gap-x-2 tw-w-1/2'>
                            <img src={filter} alt="" className='tw-w-5' />
                            <p>Divisions</p>
                        </div> */}

                        <div className='tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-border tw-rounded-lg tw-gap-x-2 tw-px-2 tw-w-full'>
                            <input onChange={e => searchWOrdHandler(e)} type="text" placeholder='Search' className='tw-outline-none tw-w-full tw-py-2' />
                            <img src={search} alt="" />
                        </div>
                    </section>

                    {/* 3rd section */}
                    <section className='tw-pb-10'>
                        {allTrainings.length !== 0 && allTrainings.map((val, index) => {
                            return <main onClick={() => handleSelectTrain(val)} key={"train" + index} className='tw-flex tw-items-center tw-px-5 tw-gap-x-5 tw-py-5 tw-border-b tw-cursor-pointer'>
                                <div>
                                    <img src={val?.aiImage} alt="" className='tw-w-16' />
                                </div>

                                <div className=''>
                                    <p>{val?.name}</p>
                                    <p className='tw-text-[#6d6d6d] tw-text-[10px]'>{val?.division.toString()}</p>

                                    <div className='tw-flex tw-gap-x-2 tw-mt-2'>
                                        {val?.character.length !== 0 && val?.character.map((charVal, cindex) => {

                                            return cindex < 2 && <p key={"char" + cindex} className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1'>
                                                <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${getCharColor()}`} ></span>
                                                <span className='tw-text-[10px]'>{charVal}</span>
                                            </p>
                                        })}
                                        {/* <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1'>
                                            <span className="tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-red-600"></span>
                                            <span className='tw-text-[10px]'>Rude</span>
                                        </p>

                                        <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1'>
                                            <span className="tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-green-600"></span>
                                            <span className='tw-text-[10px]'>Brainy</span>
                                        </p> */}
                                        {val?.character.length > 2 && <p className='tw-border tw-rounded-sm tw-px-1 tw-text-[#6d6d6d] tw-text-xs'>+2</p>}
                                    </div>
                                </div>
                            </main>
                        })}
                    </section >


                </main >

                {/* right side content - 2 */}

                {allTrainings.length !== 0 ? < main className="tw-w-[75%] tw-border tw-rounded-xl tw-p-5" >
                    <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
                        <p className="tw-font-medium tw-text-gray-700">{selectedTraining?.levels[0].title}</p>

                        <div className='tw-flex tw-gap-x-3'>
                            <img src={history} alt="" className='tw-border tw-p-2 tw-rounded-lg tw-cursor-pointer' />
                            <button onClick={() => window.location.href = '/super_admin?id=' + selectedTraining?.id} className='tw-bg-black tw-text-white tw-flex tw-items-center tw-mx-auto tw-px-4 tw-py-2 tw-rounded-lg tw-gap-x-2'>Edit </button>
                            <button onClick={() => deleteTraining()} className='tw-bg-black tw-text-white tw-flex tw-items-center tw-mx-auto tw-px-4 tw-py-2 tw-rounded-lg tw-gap-x-2'>Delete </button>
                        </div>
                    </div>

                    <main className='tw-bg-[#f6f6fa] tw-p-5 tw-rounded-lg '>
                        <div className='tw-flex tw-gap-x-2 tw-mt-2 tw-bg-white tw-w-fit tw-p-2 tw-rounded-lg'>
                            {selectedTraining?.character.map((val, index) => {
                                return <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-shadow-md'>
                                    <span className={`tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] ${getCharColor()}`}></span>
                                    <span className='tw-text-xs'>{val}</span>
                                </p>
                            })}


                            {/* <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-shadow-md'>
                                <span className="tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-green-600"></span>
                                <span className='tw-text-xs'>Brainy</span>
                            </p>

                            <p className='tw-flex tw-items-center tw-border tw-rounded-sm tw-px-1 tw-shadow-md'>
                                <span className="tw-h-[7px] tw-w-[7px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-[#ffc107]"></span>
                                <span className='tw-text-xs'>Professional</span>
                            </p> */}

                        </div>
                        <img src={selectedTraining?.aiImage} alt="" className='tw-object-cover tw-rounded-full tw-mx-auto tw-mt-32 tw-h-40 tw-w-40' />

                        <p className='tw-font-medium tw-text-3xl tw-mt-5 tw-text-center'>{selectedTraining?.name}</p>
                        <p className='tw-text-sm tw-text-[#6d6d6d] tw-text-center'>{selectedTraining?.role}</p>

                        {/* <main className='tw-flex tw-w-fit tw-mx-auto tw-gap-x-1 tw-bg-white tw-mt-20 tw-p-2 tw-rounded-full tw-mb-10'>
                            <div className='tw-flex tw-items-center tw-gap-x-2 tw-bg-[#74ff6c] tw-px-5 tw-py-3 tw-rounded-full tw-cursor-pointer'>
                                <img src={call} alt="Description of the image" className='tw-w-4' />
                                <p className='tw-font-medium'>Start Call</p>
                            </div>

                            <div className='tw-flex tw-items-center tw-gap-x-2 tw-bg-[#80c6ff] tw-px-5 tw-py-3 tw-rounded-full tw-cursor-pointer'>
                                <img src={video} alt="Description of the img" className='tw-w-4' />
                                <p className='tw-font-medium'>Start Video Call</p>
                            </div>
                        </main> */}
                        <main className='tw-flex tw-flex-col tw-w-full tw-mx-auto tw-gap-x-1 tw-bg-white tw-mt-20 tw-p-2 tw-mb-10'>
                            <label htmlFor="base-input" className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Assign to Users</label>
                            <Select className='tw-w-full' value={selectedusers} name='select-division' options={allUsers} isMulti onChange={(e) => onChangeHandler('select-division', e)} />

                        </main>
                        <button onClick={() => onSubmitHandler()} type="button" className="tw-text-white tw-bg-blue-700 hover:tw-bg-blue-800 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center ">Assign to Users</button>
                    </main>

                </main > :
                    < main className="tw-w-[75%] tw-border tw-rounded-xl tw-p-5" >No data found</main>
                }



            </section >

        </main >
    )
}

export default AIList