import React, { useState } from 'react'

import Loader from '../../layout/Loader'
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify';


const ForgotPassword = () => {

    let checkIfUserExists = true

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [isValidUser, setIsValidUser] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmitHandle = async (e) => {


        let email = e.email

        setLoading(true);

        try {


            const res = await axios.post('https://api.jarsis.ai/user/sendOtpToEmail', { email, checkIfUserExists }, {
                headers: {
                    Accept: "application/json"
                }
            });
            console.log("response------>", res)

            setLoading(false);

            if (res.data.success === true) {
                navigate('/otp', { state: { email, checkIfUserExists } })
            }
            else {
                toast.error('User Not Found')
            }
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false);
        }


    }

    return (
        <>

            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full'>
                <Loader />
            </div>

            {/* Enter your email below and we will send you a reset link */}
            <main className='tw-mx-auto tw-w-[40%] tw-my-20'>
                < div className="text-center mb-7" >
                    <h3 className="text-body-highlight">Forgot your password?</h3>
                    <p className="text-body-tertiary">Get access to your account</p>
                </div >

                <form action="" onSubmit={handleSubmit(onSubmitHandle)}>
                    {/* email */}
                    <Form.Group className="mb-3 text-start">
                        <Form.Label htmlFor="email">Email address</Form.Label>
                        <div className="form-icon-container">
                            <Form.Control
                                id="email"
                                type="email"
                                className="form-icon-input"
                                placeholder="name@example.com"
                                autoComplete='off'

                                {...register('email', {
                                    required: '*Email is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: 'Invalid email address',
                                    },
                                })}
                            />

                            {errors.email && <p className='tw-text-red-500 tw-mt-2'>{errors.email.message}</p>}

                        </div>
                    </Form.Group>

                    {/* <Link to={'/forgotpassword'} className='tw-mb-5 tw-text-sm tw-text-[#6d6d6d] hover:tw-text-gray-800'>Forgot Password?</Link> */}

                    <Button disabled={loading} variant="primary" type='submit' className="w-100 mb-3 tw-mt-5" >
                        Submit
                    </Button>
                </form>

                <div className="text-center">
                    <Link
                        to={`/`}
                        className="fs-9 fw-bold"
                    >
                        Login
                    </Link>
                </div>
            </main>

        </>
    )
}

export default ForgotPassword