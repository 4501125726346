import { useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../../layout/Loader'
import { useForm } from 'react-hook-form'


const ResetPassword = () => {

    let location = useLocation()
    const emailId = location.state && location.state.emailId

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmitHandle = async (e) => {
        if (e.password !== e.confirmpassword) {
            toast.error('Password does not match')
            return
        }
        setLoading(true);

        try {



            const res = await axios.post('https://api.jarsis.ai/user/updateUserPassword', { emailId, newPassword: e.password }, {
                headers: {
                    Accept: "application/json"
                }
            });

            if (res.data.success === true) {
                toast.success('Password updated successfully')
                navigate('/')
            }
            else {
                toast.error("something went wrong, please try again")
            }
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false);
            
        }
    }

    return (
        <>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full'>
                <Loader />
            </div>

            <h4 className="tw-text-center tw-mt-20 tw-text-2xl">Reset password</h4>
            <form className="tw-mt-20 tw-w-[30%] tw-mx-auto" onSubmit={handleSubmit(onSubmitHandle)}>
                <Form.Control className="mb-2" id="password" type="password" placeholder="Type new password"
                    {...register('password', {
                        required: '*Password is required',
                        minLength: {
                            value: 4,
                            message: '*Password must be at least 4 characters',
                        },
                        maxLength: {
                            value: 14,
                            message: '*Password cannot exceed more than 14 characters',
                        },
                    })}
                />
                {errors.password && <p className='tw-text-red-500 tw-mt-2'>{errors.password.message}</p>}


                <Form.Control className="tw-mt-5" id="confirmPassword" type="password" placeholder="Confirm new password"
                    {...register('confirmpassword', {
                        required: '*Password is required',
                        minLength: {
                            value: 4,
                            message: '*Password must be at least 4 characters',
                        },
                        maxLength: {
                            value: 14,
                            message: '*Password cannot exceed more than 14 characters',
                        },
                    })}

                />
                {errors.confirmpassword && <p className='tw-text-red-500 tw-mt-2'>{errors.confirmpassword.message}</p>}


                <Button disabled={loading} variant="primary" type='submit' className="w-100 tw-mt-5">
                    Set Password
                </Button>
            </form>

        </>
    )
}

export default ResetPassword