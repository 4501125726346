import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";

const QuestionPopup = (props) => {
    // console.log("props22222------>", props)

    const { showForm, handleCloseForm, savePromptQuestions, formData } = props;
    const { register, handleSubmit, setValue, reset, clearErrors, watch, formState: { errors } } = useForm();

    const [quesVal, setQuesVal] = useState("");

    useEffect(() => {
        reset();
        clearErrors();
        setQuesVal((typeof formData === 'string' || formData instanceof String) ? "" : formData.promptques)

    }, [formData])


    const onSubmit = data => {
        savePromptQuestions(data);
        handleCloseForm();
        reset();
        clearErrors();
    }
    return (
        <Modal show={showForm} onHide={() => handleCloseForm()}>
            <Modal.Header closeButton>
                <Modal.Title>AI Question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="hook-form" onSubmit={handleSubmit(onSubmit)}>

                    <div className="tw-mt-3 mx-auto p-20">
                        <textarea id="message" {...register("promptques", { required: true, value: quesVal })} rows="4" className="tw-block tw-p-2.5 tw-w-full tw-text-sm tw-text-gray-900 tw-bg-gray-50 tw-rounded-lg border tw-border-gray-300 focus:tw-ring-blue-500 focus:tw-border-blue-500" placeholder="Question here..."></textarea>
                    </div>
                    {errors.promptques && <p className='tw-text-red-500'>Please enter your question</p>}


                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleCloseForm()}>
                    Close
                </Button>
                <Button variant="primary" type='submit' form="hook-form">
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default QuestionPopup