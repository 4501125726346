import React, { useState } from "react";
import SideNavBar from "../layout/SideNavBar";

const D = () => {



    return (
        <SideNavBar />
    )
}

export default D