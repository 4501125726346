import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import SideNavBar from '../../layout/SideNavBar';
import Loader from '../../layout/Loader';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';

const TraineeList = () => {

    const data = {
        columns: [
            {
                label: "S. No",
                field: "sno",
                sort: "asc",
                width: 50,
            },
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Role",
                field: "role",
                sort: "asc",
                width: 200,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 100,
            },
            {
                label: "Report",
                field: "report",
                sort: "asc",
                width: 100,
            },
        ],
        rows: [],
    };


    const [candidateTraineeList, setCandidateTraineeList] = useState(data);
    const [loading, setLoading] = useState(false);
    const [showanalysis, setshowAnalysis] = useState(false);
    const [analyzeReport, setAnalyzeReport] = useState([]);
    const [rinterviewDetails, setrInterviewDetails] = useState({})
    const [showAudioChats, setShowAudioChats] = useState(false);

    const getStatus = (val) => {
        let element = <div className=''>
            <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-[#ffc107]"></span>
            <span className="">Scheduled</span>
        </div>;
        if (val.isStarted && val.isCompleted) {
            element = <div className=''>
                <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-green-600"></span>
                <span className="">Completed</span>
            </div>;
        }
        if (val.isStarted && !val.isCompleted) {
            element = <div className=''>
                <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-red-600"></span>
                <span className="">Incomplete</span>
            </div>;
        }
        return element;
    }



    useEffect(() => {

        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await axios.post('https://api.jarsis.ai/interview/getAllInterviewsByEmail', { isTraining: true, emailId: `${localStorage.getItem("user_emailId")}` }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })

                if (res.data.success && res?.data?.payload?.interviewDetails.length !== 0) {
                    console.log("res----------->", res);

                    const details = res?.data?.payload?.interviewDetails;
                    console.log('details)', details);

                    const rows = details.map((val, index) => ({
                        sno: index + 1,
                        name: val?.trainingDetails?.name ? val?.trainingDetails?.name : "",
                        role: val?.trainingDetails?.role ? val?.trainingDetails?.role : "",
                        status: getStatus(val),
                        report: val.messages.length !== 0 || val.reports.length !== 0 ? <a onClick={() => viewAnalyzeReport(val?._id)} className='tw-text-blue-400' href='javascript:void(0);'>View Analysis</a> : <p className='tw-text-black'>No Chat History</p>,
                    }));

                    setCandidateTraineeList((prevState) => ({
                        ...prevState,
                        rows,
                    }));

                } else {
                    setCandidateTraineeList([]);
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
                setCandidateTraineeList([])
            }

        }
        fetchData();

    }, [])

    const viewAnalyzeReport = async (interviewId) => {
        try {
            setLoading(true);
            const res = await axios.post('https://api.jarsis.ai/analysis/getAnalysisByInterviewId', { "interviewId": interviewId }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })

            if (res.data.success) {
                setshowAnalysis(true);
                const { payload } = res?.data;
                setAnalyzeReport(payload?.result)
                setrInterviewDetails(payload?.interviewDetails);
            } else {
                if (res.status === 350) {
                    toast.error("Sorry,No Chat history")
                } else {
                    toast.error("Sorry,Can't generate report");
                }
                setshowAnalysis(false);
                setAnalyzeReport([]);
                setrInterviewDetails({})
            }
            setLoading(false);
        } catch (e) {
            toast.error("Sorry,Can't generate report");
            setLoading(false);
            setAnalyzeReport([])
            setshowAnalysis(false);
        }
    }

    return (
        <>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-40'>
                <Loader />
            </div>
            <main className='tw-bg-[#fdfdff] tw-flex tw-gap-x-10 poppins tw-mt-6 tw-mx-6 tw-items-start tw-mb-20'>

                {/* left side menu bar */}

                <SideNavBar />

                {/* right side - main content */}
                <section className="tw-w-[80%] tw-border tw-rounded-xl tw-p-5">
                    <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
                        <p className="tw-font-medium tw-text-lg tw-text-gray-700">Trainee List</p>
                        {/* <button className='tw-text-white tw-bg-black tw-rounded-lg tw-py-2 tw-px-4'>Schedule a Train/ing</button> */}
                    </div>
                    <MDBDataTable
                        data={candidateTraineeList}
                        searching={true}
                        pagination={true}
                        noBottomColumns
                        striped
                        small
                        className="tw-rounded-lg"
                    />
                </section>

                <Modal show={showanalysis} onHide={() => setshowAnalysis(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Analyze Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400" data-tooltip-id="sentiment-tooltip">
                                Sentiment Analysis <i class="fa fa-question-circle"></i>:-
                            </h1>
                            {analyzeReport.length !== 0 && <p class="tw-text-sm tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                                {analyzeReport[0].text}
                            </p>}
                            <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400" data-tooltip-id="emotion-tooltip">
                                Emotion Analysis <i class="fa fa-question-circle"></i>:-
                            </h1>
                            {analyzeReport.length !== 0 && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                                {analyzeReport[1].text}
                            </p>}
                            <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400">
                                Vocabulary Words:-
                            </h1>
                            {analyzeReport.length !== 0 && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                                {analyzeReport[2].text}
                            </p>}
                            <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400">
                                Audio:-
                            </h1>
                            {(rinterviewDetails.isAudioCreationStarted && rinterviewDetails.isAudioCreationCompleted) && <div>

                                {/* <audio src={rinterviewDetails?.interviewAudio} controls /> */}
                                <a href="javascript:void(0);" onClick={() => setShowAudioChats(true)} class="tw-font-medium tw-text-orange-500 dark:tw-text-blue-500 hover:tw-underline">Conversation History</a>
                            </div>}
                            {(!rinterviewDetails.isAudioCreationCompleted) && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                                Processing......
                            </p>}
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setshowAnalysis(false)}>
                            Close
                        </Button>
                        {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
                    </Modal.Footer>
                </Modal>

            </main >
        </>
    )
}

export default TraineeList