import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const Alert = (props) => {
    const { showForm, from, handleCloseForm, value, addItemhandler } = props;
    return (
        <Modal show={showForm} onHide={() => handleCloseForm()}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to add this item? </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div><p>{value}</p></div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleCloseForm()}>
                    Cancel
                </Button>
                <Button variant="primary" type='submit' form="hook-form" onClick={() => addItemhandler(from)}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Alert