import React from 'react'

const Landingnew = () => {
    return (
        <div class="tw-flex tw-h-screen tw-bg-gray-50">

            <div class="tw-w-64 tw-h-screen tw-bg-white tw-border-r">
                <div class="tw-p-4 tw-flex tw-items-center tw-space-x-2">
                    <div class="tw-h-8 tw-w-8 tw-bg-blue-500 tw-rounded-full"></div>
                    <span class="tw-font-semibold tw-text-lg">jarsi.ai</span>
                </div>
                <nav class="tw-mt-6 tw-space-y-4">
                    <div>
                        <h2 class="tw-text-gray-400 tw-uppercase tw-text-xs tw-font-medium tw-px-4">Interview AI</h2>
                        <ul class="tw-space-y-2 tw-mt-2">
                            <li><a href="#" class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-blue-500 tw-bg-blue-50 tw-rounded-md">Home</a></li>
                            <li><a href="#" class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-gray-600 hover:tw-bg-gray-100 tw-rounded-md">Dashboard</a></li>
                            <li><a href="#" class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-gray-600 hover:tw-bg-gray-100 tw-rounded-md">Interview</a></li>
                            <li><a href="#" class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-gray-600 hover:tw-bg-gray-100 tw-rounded-md">Candidate List</a></li>
                        </ul>
                    </div>
                    <div>
                        <h2 class="tw-text-gray-400 tw-uppercase tw-text-xs tw-font-medium tw-px-4">Training AI</h2>
                        <ul class="tw-space-y-2 tw-mt-2">
                            <li><a href="#" class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-gray-600 hover:tw-bg-gray-100 tw-rounded-md">Trainer AI</a></li>
                            <li><a href="#" class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-gray-600 hover:tw-bg-gray-100 tw-rounded-md">Trainee List</a></li>
                        </ul>
                    </div>
                    <div>
                        <h2 class="tw-text-gray-400 tw-uppercase tw-text-xs tw-font-medium tw-px-4">Profile</h2>
                        <ul class="tw-space-y-2 tw-mt-2">
                            <li><a href="#" class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-gray-600 hover:tw-bg-gray-100 tw-rounded-md">Team</a></li>
                            <li><a href="#" class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-gray-600 hover:tw-bg-gray-100 tw-rounded-md">Settings</a></li>
                            <li><a href="#" class="tw-flex tw-items-center tw-px-4 tw-py-2 tw-text-gray-600 hover:tw-bg-gray-100 tw-rounded-md">Logout</a></li>
                        </ul>
                    </div>
                </nav>
            </div>

            <div class="tw-flex-1 tw-p-8 tw-bg-gray-50">

                <div class="tw-text-center">
                    <div class="tw-mx-auto tw-h-16 tw-w-16 tw-bg-gradient-to-r tw-from-blue-500 tw-to-purple-500 tw-rounded-full"></div>
                    <h1 class="tw-text-2xl tw-font-bold tw-mt-4">Custom AI Interviewer</h1>
                    <p class="tw-text-gray-500">AI for your recruitment process</p>
                    <button class="tw-mt-6 tw-px-6 tw-py-2 tw-bg-black tw-text-white tw-rounded-lg tw-shadow-md">Create New +</button>
                </div>


                <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-6 tw-mt-12 tw-px-8">

                    <div class="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
                        <h3 class="tw-font-semibold tw-text-lg">Initial Screening with AI</h3>
                        <p class="tw-text-gray-500 tw-mt-2">Maddy helps you do an initial preliminary screening with generic questions...</p>
                        <a href="#" class="tw-mt-4 tw-inline-block tw-text-blue-500 tw-font-medium hover:tw-underline">Get Started →</a>
                    </div>


                    <div class="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
                        <h3 class="tw-font-semibold tw-text-lg">Technical Round with AI</h3>
                        <p class="tw-text-gray-500 tw-mt-2">David helps you to do an in-depth process and technical interviews...</p>
                        <a href="#" class="tw-mt-4 tw-inline-block tw-text-blue-500 tw-font-medium hover:tw-underline">Get Started →</a>
                    </div>


                    <div class="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
                        <h3 class="tw-font-semibold tw-text-lg">Final Interview with AI</h3>
                        <p class="tw-text-gray-500 tw-mt-2">Robert will test AI into interview onboarding processes...</p>
                        <a href="#" class="tw-mt-4 tw-inline-block tw-text-blue-500 tw-font-medium hover:tw-underline">Get Started →</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landingnew