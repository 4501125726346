import React, { useEffect, useState } from 'react';
import { MDBDataTable } from 'mdbreact';
import SideNavBar from '../../layout/SideNavBar';
import { Modal, Button } from 'react-bootstrap';
import closeImg from '../../img/new/close.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../layout/Loader';
import AudioConversation from './AudioConversation';



const CandidateList = () => {


    const data = {
        columns: [
            {
                label: "S. No",
                field: "sno",
                sort: "asc",
                width: 50,
            },
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 200,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 100,
            },
            {
                label: "Report",
                field: "report",
                sort: "asc",
                width: 100,
            },
        ], rows: []
    };

    const [candidateInterview, setCandidateInterview] = useState(data);
    const [isupdated, setisUpdated] = useState(data);
    const [showanalysis, setshowAnalysis] = useState(false);
    const [analyzeReport, setAnalyzeReport] = useState([]);
    const [rinterviewDetails, setrInterviewDetails] = useState({})
    const [loading, setLoading] = useState(false);

    const getStatus = (val) => {
        let element = <div className=''>
            <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-[#ffc107]"></span>
            <span className="">Scheduled</span>
        </div>;
        if (val.isStarted && val.isCompleted) {
            element = <div className=''>
                <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-green-600"></span>
                <span className="">Completed</span>
            </div>;
        }
        if (val.isStarted && !val.isCompleted) {
            element = <div className=''>
                <span className="tw-h-[10px] tw-w-[10px] tw-inline-block tw-mr-2 tw-rounded-[50%] tw-bg-red-600"></span>
                <span className="">Incomplete</span>
            </div>;
        }
        return element;
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const res = await axios.post('https://api.jarsis.ai/interview/getAllInterviews', {}, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })

            if (res.data.success && res?.data?.payload?.interviewDetails.length !== 0) {
                const details = res?.data?.payload?.interviewDetails;

                const rows = details.map((val, index) => ({
                    sno: index + 1,
                    name: val?.candidateName ? val?.candidateName : "",
                    email: val?.candidateEmail ? val?.candidateEmail : "",
                    status: getStatus(val),
                    report: val.messages.length !== 0 || val.reports.length !== 0 ? <a className='tw-text-blue-400' href='javascript:void(0);' onClick={() => viewAnalyzeReport(val?._id)}>View Analysis</a> : <p className='tw-text-black'>No Chat History</p>,
                }));

                setCandidateInterview((prevState) => ({
                    ...prevState,
                    rows,
                }));

            } else {
                setCandidateInterview([]);
            }
            setLoading(false);
            setisUpdated(!isupdated)
        } catch (e) {
            setLoading(false);
            setCandidateInterview([])
        }

    }

    useEffect(() => {

        fetchData();

    }, [])

    useEffect(() => {

    }, [candidateInterview, isupdated])


    const viewAnalyzeReport = async (interviewId) => {
        try {
            setLoading(true);
            const res = await axios.post('https://api.jarsis.ai/analysis/getAnalysisByInterviewId', { "interviewId": interviewId }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })

            if (res.data.success) {
                setshowAnalysis(true);
                const { payload } = res?.data;
                setAnalyzeReport(payload?.result)
                setrInterviewDetails(payload?.interviewDetails);
            } else {
                if (res.status === 350) {
                    toast.error("Sorry,No Chat history")
                } else {
                    toast.error("Sorry,Can't generate report");
                }
                setshowAnalysis(false);
                setAnalyzeReport([]);
                setrInterviewDetails({})
            }
            setLoading(false);
        } catch (e) {
            toast.error("Sorry,Can't generate report");
            setLoading(false);
            setAnalyzeReport([])
            setshowAnalysis(false);
        }
    }


    // modal
    const [show, setShow] = useState(false);
    const [interviewDetails, setInterviewDetails] = useState([]);
    const [candidateName, setCandidateName] = useState("");
    const [candidateEmail, setCandidateEmail] = useState("");
    const [selectedInterview, setSelectedInterview] = useState("");
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [interviewError, setInterviewError] = useState("");
    const [showAudioChats, setShowAudioChats] = useState(false);
    //const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSelectHandle = (e) => {

        setInterviewError("");
        setSelectedInterview(e.target.value);

    }

    const onNameSelectHandle = (e) => {
        setCandidateName(e.target.value);
        setNameError("")
    }

    const onEmailSelectHandle = (e) => {
        setCandidateEmail(e.target.value);
        setEmailError("")
    }

    const onSubmitHandler = async () => {
        const name = candidateName;
        const email = candidateEmail;
        const interview = selectedInterview;

        if (!name) {
            setNameError("Please enter candidate name")
        }

        if (!email) {
            setEmailError("Please enter candidate email")
        } else {
            if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
                setEmailError("Please enter valid email")
            }
        }
        if (!interview) {
            setInterviewError("Please select Interivew type")
        }

        if (name && email && interview) {

            try {
                setLoading(true);


                const res = await axios.post('https://api.jarsis.ai/interview/createInterview', {
                    candidateName: name, candidateEmail: email, interviewQuestions: interview

                }, {
                    headers: { 'Authorization': `${localStorage.getItem("usertoken")}` }
                })
                if (res.data.success === true) {
                    handleClose();
                    toast.success("Interview Scheduled Successfully")
                    //await fetchData();
                    window.location.reload();
                } else {
                    toast.error("Something went wrong")
                }
                setLoading(false)

            } catch (e) {
                setLoading(false)
                toast.error("Something went wrong")
            }
        }
    }


    useEffect(() => {
        const getInterviewDetails = async () => {
            try {
                setLoading(true);
                const res = await axios.post('https://api.jarsis.ai/interviewQuestion/getAllInterviewQuestions', {}, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } });
                if (res.data.success) {
                    const { data } = res;

                    const { payload } = data;
                    const { interviewQuestionDetails } = payload;
                    setInterviewDetails(interviewQuestionDetails)
                } else {
                    setInterviewDetails([]);
                }
                setLoading(false);
            } catch (e) {
                setInterviewDetails([]);
                setLoading(false);
            }
        }
        getInterviewDetails();
    }, [])



    return (
        <>
            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-20 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-40'>
                <Loader />
            </div>



            <main className='tw-bg-[#fdfdff] tw-flex tw-gap-x-10 poppins tw-mt-6 tw-mx-6 tw-items-start tw-mb-20'>


                <Modal show={show} onHide={handleClose} centered >

                    <section className='tw-flex tw-justify-between tw-items-center tw-p-7 poppins tw-border-b'>
                        <div>
                            <p className='tw-font-semibold tw-text-2xl'>Schedule a Interview</p>
                            <p className='tw-text-[#6d6d6d] tw-text-sm tw-mt-1'>Al for your recruitment process</p>
                        </div>

                        <img src={closeImg} alt="" className=' tw-w-10 tw-cursor-pointer' onClick={handleClose} />
                    </section>

                    <section className='tw-p-7'>
                        <p className='tw-text-[#111111] tw-text-sm tw-mt-1'>Name</p>
                        <input type="text" onChange={(e) => onNameSelectHandle(e)} placeholder='Enter candidate name' className='tw-border tw-p-4 tw-my-3 tw-w-full tw-rounded-lg' />
                        {nameError && <p className='tw-text-red-600'>{nameError}</p>}

                        <p className='tw-text-[#111111] tw-text-sm tw-mt-1'>Email</p>
                        <input type="email" onChange={(e) => onEmailSelectHandle(e)} placeholder='Enter candidate email ID' className='tw-border tw-p-4 tw-my-3 tw-w-full tw-rounded-lg' />
                        {emailError && <p className='tw-text-red-600'>{emailError}</p>}

                        <p className='tw-text-[#111111] tw-text-sm tw-mt-1'>Interview AI</p>
                        <select onChange={e => onSelectHandle(e)} className="tw-border tw-p-4 tw-my-3 tw-w-full tw-rounded-lg ">
                            <option value="" className='tw-font-medium'>--Select--</option>
                            {interviewDetails.map((val, index) => {
                                return <option key={index} value={val?._id} className='tw-font-medium'>{val?.role}</option>
                            })}

                        </select>
                        {interviewError && <p className='tw-text-red-600'>{interviewError}</p>}

                        <button onClick={() => onSubmitHandler()} className=" tw-py-4 tw-bg-black tw-text-white tw-border-none tw-cursor-pointer tw-w-full tw-rounded-lg tw-my-3">
                            Schedule a Interview
                        </button>

                    </section>

                    <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-40'>
                        <Loader />
                    </div>

                </Modal>
                {showAudioChats && <AudioConversation showAudioChats={showAudioChats} setShowAudioChats={setShowAudioChats} newaudioData={rinterviewDetails?.interviewAudioSeparate} candidateName={rinterviewDetails?.candidateName} />}
                <Modal show={showanalysis} onHide={() => setshowAnalysis(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Analyze Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400" data-tooltip-id="sentiment-tooltip">
                                Sentiment Analysis <i class="fa fa-question-circle"></i>:-
                            </h1>
                            {analyzeReport.length !== 0 && <p class="tw-text-sm tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                                {analyzeReport[0].text}
                            </p>}
                            <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400" data-tooltip-id="emotion-tooltip">
                                Emotion Analysis <i class="fa fa-question-circle"></i>:-
                            </h1>
                            {analyzeReport.length !== 0 && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                                {analyzeReport[1].text}
                            </p>}
                            <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400">
                                Vocabulary Words:-
                            </h1>
                            {analyzeReport.length !== 0 && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                                {analyzeReport[2].text}
                            </p>}
                            <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400">
                                Audio:-
                            </h1>
                            {(rinterviewDetails.isAudioCreationStarted && rinterviewDetails.isAudioCreationCompleted) && <div>

                                {/* <audio src={rinterviewDetails?.interviewAudio} controls /> */}
                                <a href="javascript:void(0);" onClick={() => setShowAudioChats(true)} class="tw-font-medium tw-text-orange-500 dark:tw-text-blue-500 hover:tw-underline">Conversation History</a>
                            </div>}
                            {(!rinterviewDetails.isAudioCreationCompleted) && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                                Processing......
                            </p>}
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setshowAnalysis(false)}>
                            Close
                        </Button>
                        {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
                    </Modal.Footer>
                </Modal>


                {/* left side menu bar */}

                <SideNavBar />

                {/* right side - main content */}
                <section className="tw-w-[80%] tw-border tw-rounded-xl tw-p-5">
                    <div className='tw-flex tw-justify-between tw-items-center tw-my-5'>
                        <p className="tw-font-medium tw-text-lg tw-text-gray-700">Candidate List</p>
                        <button onClick={handleShow} className='tw-text-white tw-bg-black tw-rounded-lg tw-py-2 tw-px-4'>Schedule a Interview</button>
                    </div>
                    <MDBDataTable
                        data={candidateInterview}
                        searching={true}
                        pagination={true}
                        noBottomColumns
                        striped
                        small
                        className="tw-rounded-lg"
                    />
                </section>

            </main >
        </>


    )
}

export default CandidateList