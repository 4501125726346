import React, { useEffect, useState } from 'react';
import ReactFlow, { Background, Controls, useEdgesState, useNodesState } from 'reactflow';
import 'reactflow/dist/style.css';

const initialNodes = [
    { id: '1', data: { label: '-' }, position: { x: 100, y: 100 } },
    { id: '2', data: { label: 'Node 2' }, position: { x: 100, y: 200 } },
];

const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];
const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

const UpdateNode = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const [nodeName, setNodeName] = useState('Node 1');
    const [nodeBg, setNodeBg] = useState('#dbdbdb');
    const [nodeHidden, setNodeHidden] = useState(false);

    useEffect(() => {
        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === '1') {
                    // it's important that you create a new node object
                    // in order to notify react flow about the change
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            label: nodeName,
                        },
                    };
                }

                return node;
            }),
        );
    }, [nodeName, setNodes]);

    useEffect(() => {
        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === '1') {
                    // it's important that you create a new node object
                    // in order to notify react flow about the change
                    return {
                        ...node,
                        style: {
                            ...node.style,
                            backgroundColor: nodeBg,
                        },
                    };
                }

                return node;
            }),
        );
    }, [nodeBg, setNodes]);

    useEffect(() => {
        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === '1') {
                    // it's important that you create a new node object
                    // in order to notify react flow about the change
                    return {
                        ...node,
                        hidden: nodeHidden,
                    };
                }

                return node;
            }),
        );
        setEdges((eds) =>
            eds.map((edge) => {
                if (edge.id === 'e1-2') {
                    return {
                        ...edge,
                        hidden: nodeHidden,
                    };
                }

                return edge;
            }),
        );
    }, [nodeHidden, setNodes, setEdges]);

    return (
        <div style={{ height: '600px', width: '100%' }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                defaultViewport={defaultViewport}
                minZoom={0.2}
                style={{ background: '#F7F9FB' }}
                maxZoom={4}
                attributionPosition="bottom-left"
                fitView
                fitViewOptions={{ padding: 0.5 }}
            >

                <Background />
            </ReactFlow>
            <div className="update-node__controls">
                <label>label:</label>
                <input
                    value={nodeName}
                    onChange={(evt) => setNodeName(evt.target.value)}
                />

                <label className="update-node__bg-label">background:</label>
                <input value={nodeBg} onChange={(evt) => setNodeBg(evt.target.value)} />

                <div className="update-node__checkbox-wrapper">
                    <label>hidden:</label>
                    <input
                        type="checkbox"
                        checked={nodeHidden}
                        onChange={(evt) => setNodeHidden(evt.target.checked)}
                    />
                </div>
            </div>
        </div>
    );
};

export default UpdateNode;
