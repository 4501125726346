import React, { useRef } from 'react'
import SideNavBar from './SideNavBar';
import Select from 'react-select';
import { Accordion } from 'react-bootstrap';
import Configure from './Configure';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import Alert from './Alert';
import { toast } from 'react-toastify';
import Loader from '../../layout/Loader';
import { useSearchParams } from 'react-router-dom';


const SuperAdmin = () => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm()

    // [
    //     { value: 'ent', label: 'ENT' },
    //     { value: 'gyno', label: 'Gyno' },
    //     { value: 'ortho', label: 'Ortho' },
    //     { value: 'onco', label: 'Onco' },
    //     { value: 'neuro', label: 'Neuro' }
    // ]

    // { value: 'brainy', label: 'Brainy' },
    //     { value: 'rude', label: 'Rude' },
    //     { value: 'sassy', label: 'Sassy' },
    const [divisionOptions, setDivistionOptions] = useState([])

    const [charOptions, setCharOptions] = useState([])

    const [profileImage, setProfileImage] = useState("");
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [divisions, setDivisions] = useState([]);
    const [divisionsInput, setDivisionsInput] = useState("");
    const [charInput, setCharInput] = useState([]);
    const [char, setChar] = useState([]);
    const [lang, setLang] = useState("");
    const [langOptions, setLangOptions] = useState([]);
    const [levels, setLevels] = useState([]);
    const [defaultKey, setDefaultKey] = useState('0');

    const [profileImageError, setProfileImageError] = useState("");
    const [fnameError, setFnameError] = useState("");
    const [lnameError, setLnameError] = useState("");
    const [divisionsError, setDivisionsError] = useState([]);
    const [charError, setCharError] = useState([]);
    const [langError, setLangError] = useState("");
    const [showConfig, setShowConfig] = useState(false);
    const [adminData, setAdminData] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [selectedFrom, setSelectedFrom] = useState("division");
    const [loading, setLoading] = useState(false);
    const [selectedImgName, setSelectedImgName] = useState("");
    const [selectedTraining, setSelectedTraining] = useState({});


    const diviRef = useRef(null);
    const charRef = useRef(null);
    const imgRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
    }, [divisions, defaultKey, divisionOptions, charOptions, langOptions])

    useEffect(() => {
        const getDivisions = async () => {
            try {
                setLoading(true)
                const response = await axios.post('https://api.jarsis.ai/division/getAllDivisions', {}, {
                    headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
                })
                if (response?.data?.success) {
                    const { payload } = response?.data;
                    const { divisionsList } = payload;
                    const diviArray = [];
                    if (divisionsList.length !== 0) {
                        divisionsList.map((val) => {
                            diviArray.push({ label: String(val.title).charAt(0).toUpperCase() + String(val.title).slice(1), value: val._id })
                        })

                        setDivistionOptions(diviArray);
                    }

                }
                setLoading(false)
            } catch (e) {
                setLoading(false)
            }
        }

        const getCharcterstics = async () => {
            try {
                setLoading(true)
                const response = await axios.post('https://api.jarsis.ai/character/getAllCharacters', {}, {
                    headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
                })
                if (response?.data?.success) {
                    const { payload } = response?.data;
                    const { charactersList } = payload;
                    const diviArray = [];
                    if (charactersList.length !== 0) {
                        charactersList.map((val) => {
                            diviArray.push({ label: String(val.title).charAt(0).toUpperCase() + String(val.title).slice(1), value: val._id })
                        })

                        setCharOptions(diviArray);
                    }

                }
                setLoading(false)
            } catch (e) {
                setLoading(false)
            }
        }

        const getLanguages = async () => {
            try {
                setLoading(true);
                const response = await axios.post('https://api.jarsis.ai/language/getAllLanguages', {}, {
                    headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
                })
                if (response?.data?.success) {
                    const { payload } = response?.data;
                    const { languagesList } = payload;
                    const diviArray = [];
                    if (languagesList.length !== 0) {
                        languagesList.map((val) => {
                            diviArray.push({ label: String(val.title).charAt(0).toUpperCase() + String(val.title).slice(1), value: val._id })
                        })

                        setLangOptions(diviArray);
                    }

                }
                setLoading(false)
            } catch (e) {
                setLoading(false)
            }
        }

        const getTrainingById = async () => {
            try {
                setLoading(true);
                const res = await axios.post('https://api.jarsis.ai/training/getTrainingDetails', {
                    "trainingId": searchParams.get("id")
                }, {
                    headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
                })

                if (res?.data?.success) {
                    const { payload } = res?.data;
                    const { trainingDetails } = payload;
                    const trainings = {
                        id: trainingDetails?._id, aiImage: trainingDetails?.aiImage, name: trainingDetails?.name, role: trainingDetails?.role,
                        division: trainingDetails?.division.map(divi => divi?.title),
                        character: trainingDetails?.character.map(char => char?.title),
                        language: trainingDetails?.language?.title,
                        levels: trainingDetails?.levels
                    }
                    const currentData = trainingDetails;
                    const reqObj = {
                        aiImage: currentData.aiImage,
                        name: currentData.name,
                        role: currentData.role,
                        division: currentData.division,
                        character: currentData.character,
                        language: currentData.language
                    }
                    setSelectedImgName(decodeURI(currentData.aiImage.split('/')[3]))
                    setProfileImage(currentData.aiImage);
                    setFname(currentData?.name);
                    setLname(currentData?.role);
                    setDivisions(currentData?.division.map(val => { return { label: String(val?.title).charAt(0).toUpperCase() + String(val?.title).slice(1), value: val?._id } }));
                    setChar(currentData?.character.map(val => { return { label: String(val?.title).charAt(0).toUpperCase() + String(val?.title).slice(1), value: val?._id } }));
                    setLang({ value: currentData?.language?._id, label: currentData?.language?.title });
                    setLevels(currentData?.levels)
                    setAdminData(reqObj);
                    // setShowConfig(true);
                    setSelectedTraining(trainings[0]);

                }
                setLoading(false);
            } catch (error) {
                console.log("EERRRRRR------------->", error)
            }


        }

        getDivisions();
        getCharcterstics();
        getLanguages();
        if (searchParams.get("id")) {
            getTrainingById();
        }


    }, [])


    const onSubmitHandler = async () => {
        let isValid = true;
        if (!profileImage) {
            isValid = false;
            setProfileImageError("Please select ai image")
        }
        if (!fname) {
            isValid = false;
            setFnameError("Please enter first name")
        }
        if (!lname) {
            isValid = false;
            setLnameError("Please enter last name")
        }
        if (divisions.length === 0) {
            isValid = false;
            setDivisionsError("Please select divisions")
        }
        if (char.length === 0) {
            isValid = false;
            setCharError("Please select characterstics")
        }
        if (!lang) {
            isValid = false;
            setLangError("Please select characterstics")
        }
        if (isValid) {

            const reqObj = {
                aiImage: profileImage,
                name: fname,
                role: lname,
                division: divisions.map(val => val?.value),
                character: char.map(val => val?.value),
                language: lang?.value
            }
            setAdminData(reqObj)
            setShowConfig(true);
            setDefaultKey('1')
            if (searchParams.get("id")) {
                await updateTrainingDetails({
                    "trainingId": searchParams.get("id"),
                    "dataToUpdate": reqObj
                });
            }

        }


    }

    const updateTrainingDetails = async (reqObj) => {
        try {
            setLoading(true);
            const res = await axios.post('https://api.jarsis.ai/training/updateTraining', reqObj, {
                headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
            })
            if (res?.data?.success) {
                toast.success("Training data updated successfully");
            } else {
                toast.error("Training data not updated")
            }
            setLoading(false);
        } catch (error) {
            console.log("ERRRRRR---------------->", error)
            setLoading(false);
            toast.error("Something went wrong")
        }
    }

    const onChangeHandler = (name, value) => {
        switch (name) {
            case 'fname':
                setFnameError('');
                setFname(value)
                break;
            case 'lname':
                setLnameError('')
                setLname(value)
                break;
            case 'select-division':
                setDivisionsError("")
                setDivisions(value);
                break;
            case 'select-char':
                setCharError("");
                setChar(value);
                break;
            case 'division-input':
                if (diviRef.current.value) {
                    setDivisionsError("");
                    setShowAlert(!showAlert);
                }
                //setDivisions(prev => [...prev, { label: value, value }]);
                //diviRef.current.value = ""
                break;
            case 'char-input':
                if (charRef.current.value) {
                    setCharError("");
                    setSelectedFrom('char')
                    // setChar(prev => [...prev, { label: value, value }]);
                    // charRef.current.value = ""
                    setShowAlert(!showAlert);
                }
                break;

            default:
                break;
        }
    }

    const profileImageHandler = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            try {
                setLoading(true);
                setSelectedImgName(fileName);
                // e.target.setAttribute("value".fileName)
                toast.info("Please wait! we are uploading your file")
                const res = await axios.post('https://api.jarsis.ai/fileUpload/getAWSS3PresignedUrl', { filePath: fileName }, {
                    headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
                })

                if (res?.data?.success) {

                    const { payload } = res?.data;
                    const { presignedUrl, fileUrl } = payload;
                    try {
                        const response = await fetch(presignedUrl, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/octet-stream',
                            },
                            body: file,
                        });

                        if (response.ok) {
                            // imgRef.current.value = fileName
                            setLoading(false)
                            setProfileImage(fileUrl);
                        } else {
                            setLoading(false)
                            let value = URL.createObjectURL(file);

                            setProfileImage(value);
                        }
                        setProfileImageError("");

                    } catch (error) {
                        console.error("Error uploading file:", error);
                        console.log(`Error: ${error.message}`);
                        setLoading(false)
                    }
                }

            } catch (e) {

            }

        }
    }



    const addItemhandler = async (from) => {
        if (from === 'division') {
            setLoading(true)
            const res = await axios.post('https://api.jarsis.ai/division/createDivision', { title: diviRef.current.value }, {
                headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
            })
            setLoading(false)
            if (res?.data?.success) {
                const { payload } = res?.data;
                const { createdDivision } = payload;
                setDivisions(prev => [...prev, { label: String(createdDivision.title).charAt(0).toUpperCase() + String(createdDivision.title).slice(1), value: createdDivision?._id }]);
                setDivistionOptions(prev => [...prev, { label: String(createdDivision.title).charAt(0).toUpperCase() + String(createdDivision.title).slice(1), value: createdDivision?._id }])
                diviRef.current.value = ""
            }

            setShowAlert(!showAlert);
        } else {
            setLoading(true)
            const res = await axios.post('https://api.jarsis.ai/character/createCharacter', { title: charRef.current.value }, {
                headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
            })
            setLoading(false)
            if (res?.data?.success) {
                const { payload } = res?.data;
                const { createdCharacter } = payload;
                setChar(prev => [...prev, { label: String(createdCharacter.title).charAt(0).toUpperCase() + String(createdCharacter.title).slice(1), value: createdCharacter?._id }]);
                setCharOptions(prev => [...prev, { label: String(createdCharacter.title).charAt(0).toUpperCase() + String(createdCharacter.title).slice(1), value: createdCharacter?._id }]);
                charRef.current.value = ""
            }
            setShowAlert(!showAlert);
        }
    }

    const saveTraining = async () => {
        try {
            //setLoading(true)
            const reqObj = adminData;
            reqObj.levels = levels;
            if (searchParams.get("id")) {
                await updateTrainingDetails({
                    "trainingId": searchParams.get("id"),
                    "dataToUpdate": { levels: levels }
                });
            } else {
                setLoading(true)

                const response = await axios.post('https://api.jarsis.ai/training/createTraining', reqObj, {
                    headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
                });
                if (response?.data?.success) {
                    toast.success("Data saved successfully");
                    window.location.reload();
                } else {
                    toast.error("Data not saved")
                }
                setLoading(false)
            }
        } catch (e) {
            toast.error("Something went wrong")
            setLoading(false)
        }

    }


    return (
        <main className='tw-bg-[#fdfdff] tw-flex tw-gap-x-10 poppins tw-mt-6 tw-mx-6 tw-items-start'>

            {/* left side menu bar */}
            {showAlert && <Alert from={selectedFrom} showForm={showAlert} handleCloseForm={() => setShowAlert(!showAlert)} value={selectedFrom === 'division' ? diviRef.current.value : charRef.current.value} addItemhandler={addItemhandler} />}

            <div hidden={!loading} className='tw-bg-black tw-bg-opacity-50 tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-[999]'>
                <Loader />
            </div>
            <SideNavBar />
            <main className='tw-w-[80%] tw-border tw-shadow-sm tw-rounded-2xl tw-px-5 tw-pt-5 tw-mb-10'>
                <div className='tw-p-2'>
                    <Accordion defaultActiveKey={defaultKey} >
                        <Accordion.Item eventKey='0'>
                            <Accordion.Header>Basic Details</Accordion.Header>

                            <Accordion.Body>
                                <form className="tw-max-w-sm tw-mx-auto">

                                    {profileImage && <div className="tw-mb-5 tw-flex tw-justify-center">
                                        <img className="tw-rounded-full tw-w-28 tw-h-28" src={profileImage} alt="ai image" />
                                    </div>}

                                    <div className="tw-mb-5">
                                        <label className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-rounded-lg tw-border tw-border-black tw-p-5 tw-cursor-pointer tw-border-dotted" htmlFor="default_size">{selectedImgName ? selectedImgName : "Click here to add image"}</label>
                                        <input accept="image/png, image/jpeg" style={{ display: "none" }} ref={imgRef} onChange={(e) => profileImageHandler(e)} className="tw-w-full tw-mb-5 tw-text-sm tw-text-gray-900 tw-border tw-border-gray-300 tw-rounded-lg tw-cursor-pointer tw-bg-gray-50 focus:tw-outline-none" id="default_size" type="file" />
                                    </div>
                                    <p className='tw-text-red-700'>{profileImageError}</p>
                                    <div className="tw-mb-5">
                                        <label htmlFor="base-input" className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Name</label>
                                        <input type="text" autoComplete='off' value={fname} onChange={e => onChangeHandler(e.target.name, e.target.value)} name='fname' id="base-input" className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5" />
                                        <p className='tw-text-red-700'>{fnameError}</p>
                                    </div>
                                    <div className="tw-mb-5">
                                        <label htmlFor="base-input" className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Role</label>
                                        <input type="text" autoComplete='off' value={lname} onChange={e => onChangeHandler(e.target.name, e.target.value)} name='lname' id="base-input" className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5" />
                                        <p className='tw-text-red-700'>{lnameError}</p>
                                    </div>
                                    <label htmlFor="base-input" className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900">Division</label>
                                    <div className='tw-border tw-border-dotted tw-p-5 tw-rounded-xl'>
                                        <div className="tw-mb-2">

                                            <Select value={divisions} name='select-division' options={divisionOptions} isMulti onChange={(e) => onChangeHandler('select-division', e)} />
                                        </div>
                                        <div className='tw-flex tw-justify-center'> OR</div>
                                        <div className="tw-mb-5">

                                            <input type="text" ref={diviRef} onKeyUp={e => e.key === 'Enter' ? onChangeHandler('division-input', divisionsInput) : undefined} onChange={(e) => setDivisionsInput(e.target.value)} id="division-input" placeholder='Custom division and press enter' autoComplete='off' className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5" />

                                        </div>
                                    </div>
                                    <p className='tw-text-red-700'>{divisionsError}</p>
                                    <label htmlFor="base-input" className="tw-block tw-mb-2 tw-mt-3 tw-text-sm tw-font-medium tw-text-gray-900">Characterstic</label>
                                    <div className='tw-border tw-border-dotted tw-p-5 tw-rounded-xl'>
                                        <div className="tw-mb-2">
                                            <Select options={charOptions} isMulti value={char} name='select-char' onChange={(e) => onChangeHandler('select-char', e)} />
                                        </div>
                                        <div className='tw-flex tw-justify-center'> OR</div>
                                        <div className="tw-mb-5">

                                            <input type="text" ref={charRef} onKeyUp={e => e.key === 'Enter' ? onChangeHandler('char-input', charInput) : undefined} onChange={(e) => setCharInput(e.target.value)} id="char-input" placeholder='Custom character and press enter' className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5 " />
                                        </div>
                                    </div>
                                    <p className='tw-text-red-700'>{charError}</p>
                                    <div className="tw-mb-5">
                                        <label htmlFor="countries" className="tw-block tw-mb-2 tw-mt-2 tw-text-sm tw-font-medium tw-text-gray-900">Prefered Language</label>
                                        <select value={lang?.value} onChange={e => { setLang(e.target.value); setLangError("") }} id="countries" className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5">
                                            <option value="">Choose a language</option>
                                            {langOptions.map((val, index) => {
                                                return val?.value === lang?.value ? <option key={"lang" + index} value={val?.value} selected>{val?.label}</option> :
                                                    <option key={"lang" + index} value={val?.value}>{val?.label}</option>
                                            })}
                                        </select>
                                    </div>
                                    <p className='tw-text-red-700'>{langError}</p>
                                    <button onClick={() => onSubmitHandler()} type="button" className="tw-text-white tw-bg-blue-700 hover:tw-bg-blue-800 focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center ">Save</button>
                                </form>

                            </Accordion.Body>
                        </Accordion.Item>

                        {showConfig && <Accordion.Item eventKey='1'>
                            <Accordion.Header>Configration</Accordion.Header>

                            <Accordion.Body>
                                <Configure levels={levels} setLevels={setLevels} />
                            </Accordion.Body>
                        </Accordion.Item>}

                    </Accordion>

                    {showConfig && <div className='tw-flex tw-justify-end tw-gap-x-5 tw-mt-5'>

                        <button onClick={() => saveTraining()} className='tw-border-2 tw-bg-blue-500 tw-text-white tw-px-5 tw-py-1 tw-rounded-md'>Save</button>
                        <button onClick={() => window.location.reload()} className='tw-border-2 tw-bg-red-500 tw-text-white tw-px-5 tw-py-1 tw-rounded-md'>Reset</button>
                    </div>}


                </div>
            </main>
        </main >
    )
}

export default SuperAdmin